import React, { useState } from 'react';
import { useNavigate, Outlet, Link } from "react-router-dom";

function Checkout() {
    const [alamat, setAlamat] = useState('');
    const [noHp, setNoHp] = useState('');
    const [error, setError] = useState(null);
    const router = useNavigate();

    const handleCheckout = async (e) => {
        e.preventDefault();

        if (!alamat || !noHp) {
            setError("Alamat dan nomor HP harus diisi");
            return;
        }

        try {
            let token;
            if (typeof window !== 'undefined') {
                token = localStorage.getItem('token');
            }

            const response = await fetch(`${process.env.REACT_APP_API_AGROW}/order/checkout`, {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    alamat: alamat,
                    no_hp: noHp
                })
            });

            if (!response.ok) {
                throw new Error('Network response was not ok');
            }

            const data = await response.json();

            router('/orders/' + data.data);

        } catch (error) {
            console.error("Error during checkout:", error);
        }
    };

    return (
        <div className="bg-teal-50 py-6">
            <div className="relative w-screen max-w-sm border shadow-md shadow-teal-200 mx-auto bg-white px-4 py-8 sm:px-6 lg:px-8 rounded-md" aria-modal="true" role="dialog" tabIndex="-1">
                <h1 className="text-center text-teal-700 font-bold text-4xl">Checkout</h1>
                <div className="mt-10">
                    <form onSubmit={handleCheckout} className="space-y-6">
                        <input
                            type="text"
                            placeholder="Alamat"
                            value={alamat}
                            onChange={(e) => setAlamat(e.target.value)}
                            className="block w-full rounded border border-teal-600 px-4 py-2 text-sm"
                        />
                        <input
                            type="text"
                            placeholder="No HP"
                            value={noHp}
                            onChange={(e) => setNoHp(e.target.value)}
                            className="block w-full rounded border border-teal-600 px-4 py-2 text-sm"
                        />
                        {error && <p className="text-red-500">{error}</p>}
                        <button
                            type="submit"
                            className="block rounded bg-teal-700 px-5 py-3 text-sm text-white transition w-full hover:bg-teal-600"
                        >
                            Selesaikan Pesanan
                        </button>
                    </form>
                </div>
            </div>
        </div>
    );
}

export default Checkout;
