import React from 'react';

const SkeletonCard = () => {
    return (
        <div className="animate-pulse bg-gray-200 rounded-md shadow-md p-4 w-full">
            <div className="h-40 bg-gray-300 rounded mb-4"></div>
            <div className="h-6 bg-gray-300 rounded mb-2"></div>
            <div className="h-6 bg-gray-300 rounded mb-2 w-3/4"></div>
            <div className="h-6 bg-gray-300 rounded mb-2 w-1/2"></div>
        </div>
    );
};

export default SkeletonCard;
