import React, { useState, useEffect } from 'react';
import formatRupiah from '../utils/Rupiah.js';
import { useNavigate, Outlet, Link } from "react-router-dom";

function CartUser() {
    const [cart, setCart] = useState(null);
    const [order, setOrder] = useState(null);
    const [loading, setLoading] = useState(true);
    const router = useNavigate();

    const fetchCart = async () => {
        if (typeof window !== 'undefined') {
            try {
                let url = `${process.env.REACT_APP_API_AGROW}/cart/user-cart`;
                const token = localStorage.getItem('token');

                const response = await fetch(url, {
                    method: 'GET',
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-Type': 'application/json'
                    }
                });

                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }

                const data = await response.json();

                if (data && data.data && data.data.length > 0) {
                    setCart(data.data);
                }
            } catch (error) {
                console.error("Error fetching cart:", error);
            } finally {
                setLoading(false);
            }
        }
    };

    const fetchOrder = async () => {
        if (typeof window !== 'undefined') {
            try {
                let url = `${process.env.REACT_APP_API_AGROW}/order`;
                const token = localStorage.getItem('token');

                const response = await fetch(url, {
                    method: 'GET',
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-Type': 'application/json'
                    }
                });

                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }

                const data = await response.json();

                setOrder(data.data);
            } catch (error) {
                console.error("Error fetching order:", error);
            }
        }
    };

    useEffect(() => {
        fetchCart();
        fetchOrder();
    }, []);

    const handleCheckout = () => {
        router('/checkout'); // Redirect to checkout page
    };

    if (loading) {
        return (
            <div className="bg-teal-50 py-6">
                <div className="relative w-screen max-w-sm border shadow-md shadow-teal-200 mx-auto bg-white px-4 py-8 sm:px-6 lg:px-8 rounded-md" aria-modal="true" role="dialog" tabIndex="-1">
                    <h1 className="text-center text-teal-700 font-bold text-4xl">Loading...</h1>
                    <div className="mt-10 space-y-6">
                        <div className="flex justify-center">
                            <div className="loader"></div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    return (
        <div className="bg-teal-50 py-6">
            <div className="relative w-screen max-w-sm border shadow-md shadow-teal-200 mx-auto bg-white px-4 py-8 sm:px-6 lg:px-8 rounded-md" aria-modal="true" role="dialog" tabIndex="-1">
                <h1 className="text-center text-teal-700 font-bold text-4xl">Pesanan Anda !</h1>
                <div className="mt-10 space-y-6">
                    <ul className="space-y-4">
                        {cart ? cart.map((item, index) => (
                            <li key={index} className="flex items-center gap-4">
                                <img
                                    src={`${process.env.REACT_APP_API_AGROW}/product/images/${item.products.images}`}
                                    alt=""
                                    className="size-16 rounded object-cover"
                                    width={1920}
                                    height={1080}
                                    quality={100}
                                />

                                <div>
                                    <h3 className="text-md text-teal-700 font-semibold">{item.products.title}</h3>

                                    <dl className="mt-0.5 space-y-px text-[10px] text-gray-400">
                                        <div>
                                            <dt className="inline">Jumlah :</dt>
                                            <dd className="inline"> {item.quantity}</dd>
                                        </div>

                                        <div>
                                            <dt className="inline">Harga:</dt>
                                            <dd className="inline"> {formatRupiah(item.products.price)}</dd>
                                        </div>
                                    </dl>
                                </div>
                            </li>
                        )) : (
                            <p className="text-center text-teal-700">Keranjang kosong</p>
                        )}
                    </ul>

                    <div className="space-y-4 text-center">
                        <button
                            className="block rounded bg-teal-700 px-5 py-3 text-sm text-white transition w-full hover:bg-teal-600"
                            onClick={handleCheckout}
                        >
                            Checkout
                        </button>

                        <Link to="/market" className="block rounded border border-teal-600 px-5 py-3 text-sm text-teal-900 transition hover:ring-1 hover:ring-teal-400 font-semibold">
                            Lanjut Belanja
                        </Link>
                        {order && (
                            <Link to={`/orders/${order.id}`} className="block rounded border border-teal-600 px-5 py-3 text-sm text-teal-900 transition hover:ring-1 hover:ring-teal-400 font-semibold">
                                Orderan Saya
                            </Link>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default CartUser;
