import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Logo from '../../assets/images/logo.png';


function AddProduct() {
    const [categories, setCategories] = useState([]);
    const [selectedCategory, setSelectedCategory] = useState('');
    const [productName, setProductName] = useState('');
    const [stock, setStock] = useState('');
    const [productImage, setProductImage] = useState(null);
    const [price, setPrice] = useState('');
    const [tahan, setTahan] = useState('');
    const [description, setDescription] = useState('');
    const [loading, setLoading] = useState(false); // State untuk loader

    const navigate = useNavigate(); // Inisialisasi useNavigate

    useEffect(() => {
        const fetchCategories = async () => {
            try {
                let url = `${process.env.REACT_APP_API_AGROW}/category-product`;
                const response = await fetch(url);
                const data = await response.json();
                setCategories(data.data);
            } catch (error) {
                console.error("Error fetching categories:", error);
            }
        };

        fetchCategories();
    }, []);

    const handleCategoryChange = (e) => {
        setSelectedCategory(e.target.value);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true); // Set loading menjadi true saat submit

        const formData = new FormData();
        formData.append('title', productName);
        formData.append('stock', stock);
        formData.append('price', price);
        formData.append('delete', tahan);
        formData.append('file', productImage);
        formData.append('description', description);

        let token;
        if (typeof window !== 'undefined') {
            token = localStorage.getItem('token');
        }

        try {
            let url = `${process.env.REACT_APP_API_AGROW}/product/${selectedCategory}`;
            const response = await fetch(url, {
                method: 'POST',
                body: formData,
                headers: {
                    'Authorization': `Bearer ${token}`,
                }
            });

            if (response.ok) {
                // Handle success
                console.log("Product added successfully!");
                navigate(-1); // Arahkan ke halaman sebelumnya
            } else {
                // Handle error
                console.error("Error adding product:", response.statusText);
            }
        } catch (error) {
            console.error("Error adding product:", error);
        } finally {
            setLoading(false); // Set loading menjadi false setelah proses selesai
        }
    };

    const handleBack = () => {
        navigate(-1); // Arahkan ke halaman sebelumnya
    };


    return (
        <div className="bg-teal-100">
            <section className="bg-teal-100">
                <div className="mx-auto max-w-screen-xl px-4 py-16 sm:px-6 lg:px-8">
                    <div className="grid grid-cols-1 gap-x-16 gap-y-8 lg:grid-cols-5">
                        <div className="lg:col-span-2 lg:py-12">
                            <h1 className="text-3xl text-teal-700 font-extrabold">Tambah Product</h1>
                            <p className="max-w-xl text-lg">
                                Silahkan masukkan data - data produk Anda. Mulai dari nama, stock, gambar dan lain - lain. Jangan lupa untuk memasukkan ketahanan hari untuk menjaga mutu produk Anda!
                            </p>

                            <div className="mt-8">
                                <img
                                    src={Logo}
                                    alt="LOGO" width={1920}
                                    height={1080}
                                    quality={100}
                                    className="object-cover w-72 h-72 mt-2"
                                />
                            </div>
                        </div>

                        <div className="rounded-lg bg-white p-8 shadow-lg lg:col-span-3 lg:p-12">
                            <form onSubmit={handleSubmit} className="space-y-4">
                                <div>
                                    <label className="sr-only" htmlFor="name">Nama Produk</label>
                                    <input
                                        className="w-full rounded-lg border border-gray-200 p-3 text-sm"
                                        placeholder="Nama Produk"
                                        type="text"
                                        id="name"
                                        value={productName}
                                        onChange={(e) => setProductName(e.target.value)}
                                    />
                                </div>

                                <div className="grid grid-cols-1 gap-4 sm:grid-cols-2">
                                    <div>
                                        <label className="sr-only" htmlFor="stock">Stock</label>
                                        <input
                                            className="w-full rounded-lg border border-gray-200 p-3 text-sm"
                                            placeholder="Stock"
                                            type="number"
                                            id="stock"
                                            value={stock}
                                            onChange={(e) => setStock(e.target.value)}
                                        />
                                    </div>

                                    <div>
                                        <label className="sr-only" htmlFor="category">Category</label>
                                        <select
                                            className="w-full rounded-lg border border-gray-200 p-3 text-sm"
                                            id="category"
                                            value={selectedCategory}
                                            onChange={handleCategoryChange}
                                        >
                                            <option value="">Pilih Kategori</option>
                                            {categories.map((category) => (
                                                <option key={category.id} value={category.id}>
                                                    {category.nama}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                </div>

                                <div>
                                    <label className="sr-only" htmlFor="image">Gambar Produk</label>
                                    <input
                                        className="w-full rounded-lg border border-gray-200 p-3 text-sm"
                                        placeholder="Nama Produk"
                                        type="file"
                                        id="image"
                                        onChange={(e) => setProductImage(e.target.files[0])}
                                    />
                                </div>

                                <div>
                                    <label className="sr-only" htmlFor="shelf_life">Ketahanan Hari..</label>
                                    <input
                                        className="w-full rounded-lg border border-gray-200 p-3 text-sm"
                                        placeholder="Ketahanan Hari.."
                                        type="number"
                                        id="shelf_life"
                                        value={tahan}
                                        onChange={(e) => setTahan(e.target.value)}
                                    />
                                </div>
                                <div>
                                    <label className="sr-only" htmlFor="harga">Harga</label>
                                    <input
                                        className="w-full rounded-lg border border-gray-200 p-3 text-sm"
                                        placeholder="Harga"
                                        type="number"
                                        id="harga"
                                        value={price}
                                        onChange={(e) => setPrice(e.target.value)}
                                    />
                                </div>

                                <div>
                                    <label className="sr-only" htmlFor="description">Deskripsi</label>
                                    <textarea
                                        className="w-full rounded-lg border border-gray-200 p-3 text-sm"
                                        placeholder="Deskripsi"
                                        rows="8"
                                        id="description"
                                        value={description}
                                        onChange={(e) => setDescription(e.target.value)}
                                    ></textarea>
                                </div>

                                <div className="mt-4">
                                    <button
                                        type="submit"
                                        className="inline-block w-full rounded-lg bg-teal-600 px-5 py-3 font-medium text-white sm:w-auto mr-5"
                                    >
                                        {loading ? "Menambahkan..." : "Tambah"}
                                    </button>
                                    <button
                                        type="button"
                                        className="inline-block w-full rounded-lg bg-gray-200 px-5 py-3 font-medium text-slate-900 sm:w-auto mr-5"
                                        onClick={handleBack}
                                    >
                                        Kembali
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}

export default AddProduct;
