import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Logo from '../../assets/images/logo.png';
import Swal from 'sweetalert2';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

function UpdateProduct() {
    const [categories, setCategories] = useState([]);
    const [token, setToken] = useState(null);
    const [selectedCategory, setSelectedCategory] = useState('');
    const [productName, setProductName] = useState('');
    const [stock, setStock] = useState('');
    const [productImage, setProductImage] = useState(null);
    const [productImagePreview, setProductImagePreview] = useState(null);
    const [existingImage, setExistingImage] = useState(null); // Tambahkan state ini
    const [price, setPrice] = useState('');
    const [description, setDescription] = useState('');
    const [loading, setLoading] = useState(true);

    const { id } = useParams();
    const router = useNavigate();

    useEffect(() => {
        const token = window.localStorage.getItem('token');
        setToken(token);
    }, []);

    useEffect(() => {
        const fetchCategories = async () => {
            try {
                let url = `${process.env.REACT_APP_API_AGROW}/category-product`;
                const response = await fetch(url);
                const data = await response.json();
                setCategories(data.data);
            } catch (error) {
                console.error("Error fetching categories:", error);
            }
        };

        const fetchProduct = async () => {
            try {
                let url = `${process.env.REACT_APP_API_AGROW}/product/detail/${id}`;
                const response = await fetch(url);
                const data = await response.json();
                const product = data.data;
                setProductName(product.title);
                setStock(product.stock);
                setPrice(product.price);
                setDescription(product.description);
                setSelectedCategory(product.category.id);

                if (product.images) {
                    setProductImagePreview(`${process.env.REACT_APP_API_AGROW}/product/images/${product.images}`);
                    setExistingImage(product.images); // Set gambar lama ke state
                }

                console.log(product);

            } catch (error) {
                console.error("Error fetching product:", error);
            } finally {
                setLoading(false);
            }
        };

        fetchCategories();
        if (id) {
            fetchProduct();
        } else {
            setLoading(false);
        }
    }, [id]);

    const handleCategoryChange = (e) => {
        setSelectedCategory(e.target.value);
    };

    const handleImageChange = (e) => {
        const file = e.target.files[0];
        setProductImage(file);
        setProductImagePreview(URL.createObjectURL(file));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);

        const formData = new FormData();
        formData.append('title', productName);
        formData.append('stock', stock);
        formData.append('price', price);
        formData.append('description', description);

        if (productImage) {
            formData.append('file', productImage);
        } else {
            formData.append('existingImage', existingImage);
        }

        try {
            let url = `${process.env.REACT_APP_API_AGROW}/product/update/${id}`;
            const response = await fetch(url, {
                method: 'POST',
                body: formData,
                headers: {
                    'Authorization': `Bearer ${token}`,
                }
            });

            if (response.ok) {
                console.log("Product updated successfully!");
                router('/dashboard');
            } else {
                console.error("Error updating product:", response.statusText);
            }
        } catch (error) {
            console.error("Error updating product:", error);
        } finally {
            setLoading(false);
        }
    };

    const handleDelete = async () => {
        Swal.fire({
            title: 'Anda yakin?',
            text: "Produk ini akan dihapus!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#d33',
            cancelButtonColor: '#3085d6',
            confirmButtonText: 'Ya, hapus!',
            cancelButtonText: 'Batal'
        }).then(async (result) => {
            if (result.isConfirmed) {
                setLoading(true);
                try {
                    let url = `${process.env.REACT_APP_API_AGROW}/product/delete/${id}`;
                    const response = await fetch(url, {
                        method: 'GET',
                        headers: {
                            'Authorization': `Bearer ${token}`,
                        }
                    });

                    if (response.ok) {
                        Swal.fire(
                            'Deleted!',
                            'Produk telah dihapus.',
                            'success'
                        );
                        router('/dashboard');
                    } else {
                        Swal.fire(
                            'Error!',
                            'Terjadi kesalahan saat menghapus produk.',
                            'error'
                        );
                    }
                } catch (error) {
                    Swal.fire(
                        'Error!',
                        'Terjadi kesalahan saat menghapus produk.',
                        'error'
                    );
                } finally {
                    setLoading(false);
                }
            }
        });
    };

    const handleBack = () => {
        router(-1);
    };

    return (
        <div className="bg-teal-100">
            <section className="bg-teal-100">
                <div className="mx-auto max-w-screen-xl px-4 py-16 sm:px-6 lg:px-8">
                    <div className="grid grid-cols-1 gap-x-16 gap-y-8 lg:grid-cols-5">
                        <div className="lg:col-span-2 lg:py-12">
                            <h1 className="text-3xl text-teal-700 font-extrabold">Detail Product</h1>
                            <p className="max-w-xl text-lg">
                                Manajamen produk Anda untuk meningkatkan mutu produk dan kembangkan bisnis Anda !
                            </p>

                            <div className="mt-8">
                                <img
                                    src={Logo}
                                    alt="LOGO" width={1920}
                                    height={1080}
                                    quality={100}
                                    className="object-cover w-72 h-72 mt-2"
                                />
                            </div>
                        </div>

                        <div className="rounded-lg bg-white p-8 shadow-lg lg:col-span-3 lg:p-12">
                            {loading ? (
                                <div className="space-y-4">
                                    <Skeleton height={40} />
                                    <Skeleton height={40} />
                                    <Skeleton height={200} />
                                    <Skeleton height={40} />
                                    <Skeleton height={200} />
                                </div>
                            ) : (
                                <form onSubmit={handleSubmit} className="space-y-4">
                                    <div>
                                        <label className="sr-only" htmlFor="name">Nama Produk</label>
                                        <input
                                            className="w-full rounded-lg border border-gray-200 p-3 text-sm"
                                            placeholder="Nama Produk"
                                            type="text"
                                            id="name"
                                            value={productName}
                                            onChange={(e) => setProductName(e.target.value)}
                                        />
                                    </div>

                                    <div className="grid grid-cols-1 gap-4 sm:grid-cols-2">
                                        <div>
                                            <label className="sr-only" htmlFor="stock">Stock</label>
                                            <input
                                                className="w-full rounded-lg border border-gray-200 p-3 text-sm"
                                                placeholder="Stock"
                                                type="number"
                                                id="stock"
                                                value={stock}
                                                onChange={(e) => setStock(e.target.value)}
                                            />
                                        </div>

                                        <div>
                                            <label className="sr-only" htmlFor="category">Category</label>
                                            <select
                                                className="w-full rounded-lg border border-gray-200 p-3 text-sm"
                                                id="category"
                                                value={selectedCategory}
                                                onChange={handleCategoryChange}
                                            >
                                                <option value="">Pilih Kategori</option>
                                                {categories.map((category) => (
                                                    <option key={category.id} value={category.id}>
                                                        {category.nama}
                                                    </option>
                                                ))}
                                            </select>
                                        </div>
                                    </div>

                                    <div>
                                        <label className="sr-only" htmlFor="image">Gambar Produk</label>
                                        {productImagePreview && (
                                            <div className="mb-4">
                                                <img
                                                    src={productImagePreview}
                                                    alt="Product Preview"
                                                    className="object-cover w-full h-64 rounded-lg"
                                                />
                                            </div>
                                        )}
                                        <input
                                            className="w-full rounded-lg border border-gray-200 p-3 text-sm"
                                            type="file"
                                            id="image"
                                            onChange={handleImageChange}
                                        />
                                    </div>

                                    <div>
                                        <label className="sr-only" htmlFor="price">Harga</label>
                                        <input
                                            className="w-full rounded-lg border border-gray-200 p-3 text-sm"
                                            placeholder="Harga"
                                            type="number"
                                            id="price"
                                            value={price}
                                            onChange={(e) => setPrice(e.target.value)}
                                        />
                                    </div>

                                    <div>
                                        <label className="sr-only" htmlFor="description">Deskripsi</label>
                                        <textarea
                                            className="w-full rounded-lg border border-gray-200 p-3 text-sm"
                                            placeholder="Deskripsi"
                                            rows="8"
                                            id="description"
                                            value={description}
                                            onChange={(e) => setDescription(e.target.value)}
                                        ></textarea>
                                    </div>

                                    <div className="mt-4">
                                        <button
                                            type="submit"
                                            className="inline-block w-full rounded-lg bg-teal-600 px-5 py-3 font-medium text-white sm:w-auto mr-5"
                                        >
                                            {loading ? "Mengupdate..." : "Update"}
                                        </button>
                                        <button
                                            type="button"
                                            className="inline-block w-full rounded-lg bg-red-600 px-5 py-3 font-medium text-white sm:w-auto mr-5"
                                            onClick={handleDelete}
                                        >
                                            Hapus
                                        </button>
                                        <button
                                            type="button"
                                            className="inline-block w-full rounded-lg bg-gray-200 px-5 py-3 font-medium text-slate-900 sm:w-auto mr-5"
                                            onClick={handleBack}
                                        >
                                            Kembali
                                        </button>
                                    </div>
                                </form>
                            )}
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
}

export default UpdateProduct;
