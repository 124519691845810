import React from 'react';

const SkeletonLoader = () => {
    return (
        <div className='bg-teal-100'>
            <div className="p-5 mx-auto sm:p-10 md:p-16 shadow-lg">
                <div className="flex flex-col max-w-3xl md:max-w-5xl mx-auto overflow-hidden rounded shadow-lg md:my-22 bg-white animate-pulse">
                    <div className="w-full h-60 sm:h-96 bg-gray-300"></div>
                    <div className="p-6 pb-12 m-4 mx-auto -mt-16 space-y-6 lg:max-w-5xl sm:px-10 sm:mx-12 h-58 lg:rounded-md bg-teal-500 overflow-y-auto">
                        <div className="space-y-2">
                            <div className="h-8 bg-gray-300 rounded w-3/4"></div>
                            <div className="h-4 bg-gray-300 rounded w-1/4"></div>
                        </div>
                        <div className="space-y-2">
                            <div className="h-4 bg-gray-300 rounded"></div>
                            <div className="h-4 bg-gray-300 rounded"></div>
                            <div className="h-4 bg-gray-300 rounded"></div>
                            <div className="h-4 bg-gray-300 rounded"></div>
                            <div className="h-4 bg-gray-300 rounded"></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default SkeletonLoader;
