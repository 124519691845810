import React, { useEffect, useState, useRef } from 'react';
import { useNavigate, Outlet, Link, useLocation } from "react-router-dom";
import { ShoppingCartIcon } from '@heroicons/react/solid';
import Logo from '../assets/images/logo.png';
import Petani from '../assets/images/petani.png';

function Navbar() {
    const location = useLocation();
    const currentPath = location.pathname;

    const [menuOpen, setMenuOpen] = useState(false);
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const [isLogin, setIsLogin] = useState(null);
    const [user, setUser] = useState(null);
    const dropdownRef = useRef(null);

    const fetchUserLogin = async (token) => {
        try {
            const response = await fetch(`${process.env.REACT_APP_API_AGROW}/me`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                },
            });
            const data = await response.json();
            setUser(data);
        } catch (error) {
            console.error("Error details:", error);
        }
    };

    const handleLogout = async () => {
        try {
            let token;
            if (typeof window !== 'undefined') {
                token = localStorage.getItem('token');
            }

            const response = await fetch(`${process.env.REACT_APP_API_AGROW}/logout`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                },
            });

            if (response.ok) {
                if (typeof window !== 'undefined') {
                    localStorage.removeItem('token');
                }
                setIsLogin(null);
                setUser(null);
                window.location.href = '/';
            }
        } catch (error) {
            console.error("Error details:", error);
        }
    };

    const toggleMenu = () => {
        setMenuOpen(!menuOpen);
    };

    const toggleDropdown = () => {
        setDropdownOpen(!dropdownOpen);
    };

    const handleClickOutside = (event) => {
        if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
            setDropdownOpen(false);
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    useEffect(() => {
        if (typeof window !== 'undefined') {
            const storedToken = localStorage.getItem('token');
            if (storedToken) {
                setIsLogin(storedToken);
                fetchUserLogin(storedToken);
            } else {
                setIsLogin(null);
                setUser(null);
            }
        }
    }, [currentPath]);

    return (
        <>
            <header className={currentPath === "/auth/login" ? "hidden" : "bg-white sticky top-0 z-50 shadow-md"}>
                <div className="mx-auto flex h-16 max-w-screen-2xl items-center gap-8 px-4 sm:px-6 lg:px-8">
                    <Link to="/" className="block text-teal-600">
                        <img className='h-10 w-12' src={Logo} alt="Logo" />
                    </Link>
                    <div className="flex flex-1 items-center justify-end md:justify-between">
                        <nav aria-label="Global" className="hidden md:block">
                            <ul className="flex items-center gap-6 text-sm">
                                <li className={isLogin ? 'hidden' : 'block'}>
                                    <Link className="text-gray-500 transition hover:text-gray-500/75" to="/">Home</Link>
                                </li>
                                <li className={isLogin ? 'hidden' : 'block'}>
                                    <Link className="text-gray-500 transition hover:text-gray-500/75" to="/about">About</Link>
                                </li>
                                <li>
                                    <Link className="text-gray-500 transition hover:text-gray-500/75" to="/market">Market</Link>
                                </li>
                                <li>
                                    <Link className="text-gray-500 transition hover:text-gray-500/75" to="/education">Education</Link>
                                </li>
                                <li>
                                    <Link className="text-gray-500 transition hover:text-gray-500/75" to="/contact">Contact</Link>
                                </li>
                            </ul>
                        </nav>
                        <div className="flex items-center gap-4">
                            <div className={isLogin ? 'hidden' : "sm:flex sm:gap-4"}>
                                <Link className="rounded-md bg-teal-600 px-5 py-2.5 text-sm font-medium text-white transition hover:bg-teal-700 flex items-center" to="/auth/login">
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-6 h-6 mr-2">
                                        <path d="M12 2C9.243 2 7 4.243 7 7c0 1.568.67 2.982 1.738 3.988C6.94 12.9 4 15.343 4 18v2h16v-2c0-2.657-2.94-5.1-4.738-7.012C16.33 9.982 17 8.568 17 7c0-2.757-2.243-5-5-5zm0 2c1.654 0 3 1.346 3 3s-1.346 3-3 3-3-1.346-3-3 1.346-3 3-3zM6 18c0-2.41 2.434-4.5 6-4.5s6 2.09 6 4.5v2H6v-2z" />
                                    </svg>
                                    Login Akun
                                </Link>
                            </div>
                            <div className={isLogin ? 'relative flex items-center gap-4' : 'hidden'}>
                                {
                                    user && (
                                        <Link to={`/cart`}>
                                            <ShoppingCartIcon className="w-6 h-6 ml-2 text-teal-600" />
                                        </Link>
                                    )
                                }
                                <div ref={dropdownRef} className={isLogin ? "border border-teal-600 rounded-full flex items-center" : 'hidden'}>
                                    <button onClick={toggleDropdown} className="flex items-center">
                                        <img src={Petani} alt="Avatar" className="rounded-full w-10 h-10" />
                                    </button>

                                    {dropdownOpen && (
                                        <div className="absolute right-0 mt-2 w-48 bg-white border rounded shadow-lg">
                                            <Link to={user ? `/dashboard` : ''} className="block px-4 py-2 text-gray-700 hover:bg-gray-100">Dashboard</Link>
                                            <button onClick={handleLogout} className="block px-4 py-2 text-gray-700 hover:bg-gray-100 w-full text-left">Sign Out</button>
                                        </div>
                                    )}
                                </div>
                            </div>
                            <button onClick={toggleMenu} className="block rounded bg-gray-100 p-2.5 text-gray-600 transition hover:text-gray-600/75 md:hidden">
                                <span className="sr-only">Toggle menu</span>
                                {menuOpen ? (
                                    <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2">
                                        <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
                                    </svg>
                                ) : (
                                    <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2">
                                        <path strokeLinecap="round" strokeLinejoin="round" d="M4 6h16M4 12h16M4 18h16" />
                                    </svg>
                                )}
                            </button>
                        </div>
                    </div>
                </div>
                {menuOpen && (
                    <div className="md:hidden border-t">
                        <ul className="flex flex-col items-center gap-4 text-sm mt-4">
                            <li className={isLogin ? 'hidden' : 'block w-full border-b'}>
                                <Link className="block w-full text-center py-2 text-gray-500 transition hover:text-gray-500/75" to="/" onClick={() => setMenuOpen(false)}>Home</Link>
                            </li>
                            <li className={isLogin ? 'hidden' : 'block w-full border-b'}>
                                <Link className="block w-full text-center py-2 text-gray-500 transition hover:text-gray-500/75" to="/about" onClick={() => setMenuOpen(false)}>About</Link>
                            </li>
                            <li className="w-full border-b">
                                <Link className="block w-full text-center py-2 text-gray-500 transition hover:text-gray-500/75" to="/market" onClick={() => setMenuOpen(false)}>Market</Link>
                            </li>
                            <li className="w-full border-b">
                                <Link className="block w-full text-center py-2 text-gray-500 transition hover:text-gray-500/75" to="/education" onClick={() => setMenuOpen(false)}>Education</Link>
                            </li>
                            <li className="w-full">
                                <Link className="block w-full text-center py-2 text-gray-500 transition hover:text-gray-500/75" to="/contact" onClick={() => setMenuOpen(false)}>Contact</Link>
                            </li>
                        </ul>
                    </div>
                )}
            </header>
        </>
    );
}

export default Navbar;
