import React, { useEffect, useState } from 'react';
import { useNavigate, Outlet, Link } from "react-router-dom";
import Logo from './assets/images/logo.png';
import FiturMarket from './assets/images/ftm.png';
import FiturEducation from './assets/images/ftu.png';
import FiturCatat from './assets/images/fty.png';
import CardProduct from "./components/CardProduct.js";
import CardEducation from "./components/CardEducation.js";
import Dagang from "./assets/images/dagang2.jpg";
import SkeletonCard from './components/SkeletonCard.js';
import useMobileDetect from './function/checkMobile.js';


function Home() {
    let isMobile = useMobileDetect();
    const [token, setToken] = useState(null);
    const [products, setProducts] = useState(null);
    const [educations, setEducations] = useState([]);
    const [formData, setFormData] = useState({
        name: "",
        email: "",
        password: ""
    });
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState("");
    const router = useNavigate();

    const fetchProducts = async () => {
        try {
            const url = `${process.env.REACT_APP_API_AGROW}/product`;
            const response = await fetch(url);
            const data = await response.json();
            setProducts(data.data);
        } catch (error) {
            console.error("Error fetching products:", error);
        }
    };

    const fetchEducation = async () => {
        try {
            const url = `${process.env.REACT_APP_API_AGROW}/education`;
            const response = await fetch(url);
            const data = await response.json();
            setEducations(data.data);
        } catch (error) {
            console.error("Error fetching educations:", error);
        }
    };

    useEffect(() => {
        if (typeof window !== 'undefined') {
            const storedToken = localStorage.getItem('token');
            setToken(storedToken);
            if (storedToken) {
                router('/market');
            }
        }

        fetchProducts();
        fetchEducation();
    }, []);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevFormData) => ({
            ...prevFormData,
            [name]: value,
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        setError("");

        try {
            const response = await fetch(`${process.env.REACT_APP_API_AGROW}/register`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(formData),
            });

            const data = await response.json();

            if (response.ok) {
                // Redirect user or show success message
                if (data.status === 201) {
                    const responseLogin = await fetch(`${process.env.REACT_APP_API_AGROW}/login`, {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json',
                            'Access-Control-Allow-Origin': '*'
                        },
                        body: JSON.stringify({ email: formData.email, password: formData.password }),
                    });

                    const dataLogin = await responseLogin.json();  // Set loading state to false

                    if (dataLogin) {
                        if (dataLogin.status === 200) {
                            if (typeof window !== 'undefined') {
                                localStorage.setItem('token', dataLogin.token);
                            }
                            router('/market');
                        } else {
                            console.log(token);
                        }
                    }
                }
            } else {
                // Handle error
                setError(data.message);
            }
        } catch (err) {
        } finally {
            setLoading(false);
        }
    };

    console.log(products);
    console.log(educations);

    return (
        <>
            <div className="relative">
                <img
                    src={Dagang}
                    className="absolute inset-0 md:object-cover object-center w-full h-full"
                    alt="Dagang"
                />
                <div className="relative bg-opacity-75 bg-teal-700">
                    <svg
                        className="absolute inset-x-0 bottom-0 text-white"
                        viewBox="0 0 1160 163"
                    >
                        <path
                            fill="currentColor"
                            d="M-164 13L-104 39.7C-44 66 76 120 196 141C316 162 436 152 556 119.7C676 88 796 34 916 13C1036 -8 1156 2 1216 7.7L1276 13V162.5H1216C1156 162.5 1036 162.5 916 162.5C796 162.5 676 162.5 556 162.5C436 162.5 316 162.5 196 162.5C76 162.5 -44 162.5 -104 162.5H-164V13Z"
                        />
                    </svg>
                    <div className="relative px-4 py-16 mx-auto overflow-hidden sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-20">
                        <div className="flex flex-col items-center justify-between xl:flex-row">
                            <div className="w-full max-w-xl mb-12 xl:mb-0 xl:pr-16 xl:w-7/12">
                                <h2 className="max-w-lg mb-6 font-sans text-3xl font-bold tracking-tight text-white sm:text-4xl sm:leading-none">
                                    Minimum the waste then <br className="hidden md:block" />
                                    Maximum the profit.
                                </h2>
                                <p className="max-w-xl mb-4 text-base text-gray-200 md:text-lg">
                                    Bergabunglah dengan kami di <b className="text-teal-200">AGROW</b>,kami mengutamakan efisiensi dan keberlanjutan.Jadikan bisnis Anda lebih ramah lingkungan dan lebih menguntungkan!
                                </p>
                            </div>
                            <div className="w-full max-w-xl xl:px-8 xl:w-5/12">
                                <div className="bg-white rounded shadow-2xl p-7 sm:p-10">
                                    <h3 className="mb-4 text-xl font-semibold sm:text-center sm:mb-6 sm:text-xl">
                                        Silahkan bergabung dengan kami !
                                    </h3>


                                    <form onSubmit={handleSubmit}>
                                        <div className="mb-1 sm:mb-2">
                                            <label
                                                htmlFor="firstName"
                                                className="inline-block mb-1 font-medium"
                                            >
                                                Nama Lengkap
                                            </label>
                                            <input
                                                placeholder="Masukkan nama lengkap"
                                                required
                                                type="text"
                                                className="flex-grow w-full h-12 px-4 mb-2 transition duration-200 bg-white border border-gray-300 rounded shadow-sm appearance-none focus:border-deep-purple-accent-400 focus:outline-none focus:shadow-outline"
                                                id="name"
                                                name="name"
                                                value={formData.name}
                                                onChange={handleChange}
                                            />
                                        </div>
                                        <div className="mb-1 sm:mb-2">
                                            <label
                                                htmlFor="lastName"
                                                className="inline-block mb-1 font-medium"
                                            >
                                                Email
                                            </label>
                                            <input
                                                placeholder="Masukkan email"
                                                required
                                                type="text"
                                                className="flex-grow w-full h-12 px-4 mb-2 transition duration-200 bg-white border border-gray-300 rounded shadow-sm appearance-none focus:border-deep-purple-accent-400 focus:outline-none focus:shadow-outline"
                                                id="email"
                                                name="email"
                                                value={formData.email}
                                                onChange={handleChange}
                                            />
                                        </div>
                                        <div className="mb-1 sm:mb-2">
                                            <label
                                                htmlFor="email"
                                                className="inline-block mb-1 font-medium"
                                            >
                                                Password
                                            </label>
                                            <input
                                                placeholder="Masukkan password Anda"
                                                required
                                                type="password"
                                                className="flex-grow w-full h-12 px-4 mb-2 transition duration-200 bg-white border border-gray-300 rounded shadow-sm appearance-none focus:border-deep-purple-accent-400 focus:outline-none focus:shadow-outline"
                                                id="password"
                                                name="password"
                                                value={formData.password}
                                                onChange={handleChange}
                                            />
                                        </div>
                                        {error && <p className="text-xs text-left text-red-600 sm:text-sm">{error}</p>}
                                        <div className="mt-4 mb-2 sm:mb-4">
                                            <button
                                                type="submit"
                                                className="inline-flex items-center justify-center w-full h-12 px-6 font-medium tracking-wide text-white transition duration-200 rounded shadow-md bg-teal-600 focus:shadow-outline focus:outline-none"
                                                disabled={loading}
                                            >
                                                {loading ? "Loading..." : "Daftar"}
                                            </button>
                                        </div>

                                        <p className="text-xs text-center text-gray-600 sm:text-sm">
                                            Kemanan data dan privasi kami jamin!
                                        </p>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* // About */}
            <div className="px-4 py-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-20">
                <div className="max-w-xl mb-10 md:mx-auto sm:text-center lg:max-w-2xl md:mb-12">
                    <div>
                        <p className="inline-block px-3 py-px mb-4 text-xs font-semibold tracking-wider text-teal-700 uppercase rounded-full bg-teal-accent-400">
                            AGROW
                        </p>
                    </div>
                    <h2 className="max-w-lg mb-6 font-sans text-3xl font-bold leading-none tracking-tight text-gray-900 sm:text-4xl md:mx-auto">
                        <span className="relative inline-block">
                            <svg
                                viewBox="0 0 52 24"
                                fill="currentColor"
                                className="absolute top-0 left-0 z-0 hidden w-32 -mt-8 -ml-20 text-teal-500 lg:w-32 lg:-ml-28 lg:-mt-10 sm:block"
                            >
                                <defs>
                                    <pattern
                                        id="db164e35-2a0e-4c0f-ab05-f14edc6d4d30"
                                        x="0"
                                        y="0"
                                        width=".135"
                                        height=".30"
                                    >
                                        <circle cx="1" cy="1" r=".7" />
                                    </pattern>
                                </defs>
                                <rect
                                    fill="url(#db164e35-2a0e-4c0f-ab05-f14edc6d4d30)"
                                    width="52"
                                    height="24"
                                />
                            </svg>
                            <span className="relative">Semua</span>
                        </span>{' '}
                        Fitur - fitur unggulan <b className="text-teal-600">AGROW</b>
                    </h2>
                    <p className="text-base text-gray-700 md:text-lg">
                        Kami siap membantu Anda mencapai produktivitas yang lebih tinggi dan mengelola bisnis dengan lebih efisien.
                    </p>
                </div>


                <div className="grid md:max-w-sm gap-5 mb-8 lg:grid-cols-3 mx-auto lg:max-w-full">
                    <div className="md:max-w-xs w-full p-6 rounded-md shadow-md hover:shadow-teal-200 bg-white ">
                        <img src={FiturMarket} alt="" className="object-cover object-center w-full rounded-md h-72 bg-teal-300 "
                            width={1920}
                            height={1080}
                            quality={100} />
                        <div className="mt-6 mb-2">
                            <span className="block text-xs text-teal-500 font-medium tracking-widest uppercase ">Fitur</span>
                            <h2 className="text-xl font-semibold tracking-wide">Fitur E-Commerece</h2>
                        </div>
                        <p className="">Belanja online cepat dan mudah dimanapun & kapanpun</p>
                    </div>
                    <div className="md:max-w-xs w-full p-6 rounded-md shadow-md hover:shadow-teal-200 bg-white ">
                        <img src={FiturEducation} alt="" className="object-cover object-center w-full rounded-md h-72 bg-teal-300 "
                            width={1920}
                            height={1080}
                            quality={100} />
                        <div className="mt-6 mb-2">
                            <span className="block text-xs text-teal-500 font-medium tracking-widest uppercase ">Fitur</span>
                            <h2 className="text-xl font-semibold tracking-wide">Fitur Edukasi</h2>
                        </div>
                        <p className="">Untuk meningkatkan pengetahuan dan referensi informatif untuk kemajuan bisnis!</p>
                    </div>
                    <div className="md:max-w-xs w-full p-6 rounded-md shadow-md hover:shadow-teal-200 bg-white ">
                        <img src={FiturCatat} alt="" className="object-cover object-center w-full rounded-md h-72 bg-teal-300 "
                            width={1920}
                            height={1080}
                            quality={100} />
                        <div className="mt-6 mb-2">
                            <span className="block text-xs text-teal-500 font-medium tracking-widest uppercase ">Fitur</span>
                            <h2 className="text-xl font-semibold tracking-wide">Yuk Catat</h2>
                        </div>
                        <p className=""> Dirancang untuk memberikan kemudahan dalam mencatat dan mengelola informasi yang relevan dengan kebutuhan belanja.</p>
                    </div>

                </div>
            </div>

            {/* Jargon */}
            <div className="w-full banner-img bg-blend-soft-light h-[24rem] relative">
                <div className="w-full absolute bg-teal-600 bg-opacity-50 h-full">
                    <div className="flex items-center justify-center w-full h-full bg-gray-900/40">
                        <div className="text-center">
                            <h1 className="text-2xl font-semibold text-white md:text-6xl">Kembangkan bisnismu bersama <span className="text-teal-400 mb-12">AGROW</span> disini.</h1>
                            <Link to="/auth/login" className="md:w-full max-w-md px-5 py-2 md:px-8 md:py-3 mt-4 text-sm font-medium text-white capitalize transition-colors duration-300 transform bg-teal-600 rounded-md lg:w-auto hover:bg-teal-500 focus:outline-none focus:bg-teal-500 ">Gabung</Link>
                        </div>
                    </div>
                </div>
            </div>

            {/* Produk */}
            <div className="w-full md:py-12 md:px-32 p-4 bg-white">
                <div className="md:flex md:items-end flex-col md:flex-row justify-between mb-12 header">
                    <div className="title">
                        <p className="mb-4 text-4xl font-bold text-teal-600">
                            Produk Pilihan !
                        </p>
                        <p className="md:text-2xl text-md font-light text-gray-400">
                            Nikmati produk pilihan Agrow yang diseleksi dengan teliti untuk kualitas terbaik.
                        </p>
                    </div>
                    <div className="md:text-end block mt-8 md:mt-0">
                        <Link to="/market" className="flex-shrink-0 px-4 py-2 text-base font-semibold text-white bg-teal-600 rounded-lg shadow-md hover:bg-teal-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-purple-200" type="submit">
                            Lihat Semua
                        </Link>
                    </div>
                </div>
                <div className="grid grid-cols-1 gap-12 md:grid-cols-2 lg:grid-cols-4">
                    {products ? (
                        products.length > 0 ? (
                            products.map((product) => (
                                <CardProduct
                                    key={product.id}
                                    img={`${process.env.REACT_APP_API_AGROW}/product/images/${product.images}`}
                                    title={product.title}
                                    desc={product.description}
                                    price={product.price}
                                    isMarket={true}
                                    isMobile={isMobile}
                                    id={product.id}
                                />
                            ))
                        ) : (
                            Array.from({ length: 6 }).map((_, index) => (
                                <SkeletonCard key={index} />
                            ))
                        )
                    ) : (
                        Array.from({ length: 6 }).map((_, index) => (
                            <SkeletonCard key={index} />
                        ))
                    )}
                </div>
            </div>

            {/* Education */}
            <div className="w-full md:py-12 md:px-32 p-4 bg-white">
                <div className="md:flex md:items-end flex-col md:flex-row justify-between mb-12 header">
                    <div className="title">
                        <p className="mb-4 text-4xl font-bold text-teal-600">
                            Education for Grow !
                        </p>
                        <p className="md:text-2xl text-md font-light text-gray-400">
                            Akses pembelajaran berkualitas baik untuk kemajuan bisnis Anda !
                        </p>
                    </div>
                    <div className="md:text-end block mt-8 md:mt-0">
                        <Link to="/education" className="flex-shrink-0 px-4 py-2 text-base font-semibold text-white bg-teal-600 rounded-lg shadow-md hover:bg-teal-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-purple-200" type="submit">
                            Lihat Semua
                        </Link>
                    </div>
                </div>
                <div className="grid grid-cols-1 gap-12 md:grid-cols-2 xl:grid-cols-3">
                    {educations ? (
                        educations.length > 0 ? (
                            educations.map((education) => (
                                <CardEducation key={education.id} thumbnail={`${process.env.REACT_APP_API_AGROW}/education/thumbnail/${education.images}`} category={education.category_edu.nama_education} title={education.name_education} desc={education.description} id={education.id} />
                            ))
                        ) : (
                            Array.from({ length: 6 }).map((_, index) => (
                                <SkeletonCard key={index} />
                            ))
                        )
                    ) : (
                        Array.from({ length: 6 }).map((_, index) => (
                            <SkeletonCard key={index} />
                        ))
                    )}
                </div>
            </div>
            <Outlet />
        </>
    )
}

export default Home
