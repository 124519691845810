import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faArrowRight } from '@fortawesome/free-solid-svg-icons';
import Dagang from "../assets/images/dagang2.jpg";

function CarouselMarket() {
    const images = [
        Dagang,
        Dagang,
        Dagang,
    ];

    const [currentImage, setCurrentImage] = useState(0);

    const goToNextImage = () => {
        setCurrentImage((prevImage) => (prevImage === images.length - 1 ? 0 : prevImage + 1));
    };

    const goToPrevImage = () => {
        setCurrentImage((prevImage) => (prevImage === 0 ? images.length - 1 : prevImage - 1));
    };

    return (
        <div className="relative w-full h-96 md:h-96">
            <div className="overflow-hidden w-full h-full">
                <div className="flex transition-transform duration-300" style={{ transform: `translateX(-${currentImage * 100}%)` }}>
                    {images.map((imageUrl, index) => (
                        <div key={index} className="w-full flex-shrink-0" style={{ height: '350px' }}>
                            <img
                                src={imageUrl}
                                alt={`Image ${index + 1}`}
                                className="w-full h-full object-cover"
                                width={300}
                                height={300}
                            />
                        </div>

                    ))}
                </div>
            </div>
            <button
                className="absolute left-0 top-1/2 transform -translate-y-1/2 bg-teal-800 text-white p-2 rounded-md"
                onClick={goToPrevImage}
            >
                <FontAwesomeIcon icon={faArrowLeft} className="w-5 h-5 text-grey-500 " />
            </button>
            <button
                className="absolute right-0 top-1/2 transform -translate-y-1/2 bg-teal-800 text-white p-2 rounded-md"
                onClick={goToNextImage}
            >
                <FontAwesomeIcon icon={faArrowRight} className="w-5 h-5 text-grey-500 " />
            </button>
        </div>
    );
}

export default CarouselMarket;
