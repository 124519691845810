import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from "react-router-dom";
import formatRupiah from '../utils/Rupiah.js';

function OrderDetail() {
    const [order, setOrder] = useState(null);
    const [loading, setLoading] = useState(true);
    const router = useNavigate();
    const { orderId } = useParams()
    const idOd = orderId;

    useEffect(() => {
        const snapScript = "https://app.midtrans.com/snap/snap.js";
        const midtransKey = process.env.REACT_APP_MIDTRANS_CLIENT;

        const script = document.createElement("script");
        script.src = snapScript;
        script.setAttribute('data-client-key', midtransKey);
        script.async = true;
        document.body.appendChild(script);

        return () => {
            document.body.removeChild(script);
        };
    }, []);

    useEffect(() => {
        if (idOd) {
            fetchOrderDetail(idOd);
        }
    }, [idOd]);

    const fetchOrderDetail = async (orderId) => {
        try {
            let token;
            if (typeof window !== 'undefined') {
                token = localStorage.getItem('token');
            }

            const response = await fetch(`${process.env.REACT_APP_API_AGROW}/order/${orderId}`, {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json'
                }
            });

            if (!response.ok) {
                throw new Error('Network response was not ok');
            }

            const data = await response.json();
            setOrder(data.data);
            console.log(data);
        } catch (error) {
            console.error("Error fetching order detail:", error);
        } finally {
            setLoading(false);
        }
    };

    const handlePayment = async () => {
        try {
            const response = await fetch(`${process.env.REACT_APP_API_AGROW}/midtrans/token`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    order_id: idOd,
                    gross_amount: order.total
                })
            });

            const data = await response.json();
            const token = data.token;

            if (typeof window !== 'undefined') {
                window.snap.pay(token);
            }
        } catch (error) {
            console.error("Error getting Midtrans token:", error);
        }
    };

    if (loading) {
        return (
            <div className="bg-teal-50 py-6">
                <div className="relative w-screen max-w-sm border shadow-md shadow-teal-200 mx-auto bg-white px-4 py-8 sm:px-6 lg:px-8 rounded-md" aria-modal="true" role="dialog" tabIndex="-1">
                    <h1 className="text-center text-teal-700 font-bold text-4xl">Loading...</h1>
                    <div className="mt-10 space-y-6">
                        <div className="flex justify-center">
                            <div className="loader"></div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    if (!order) {
        return (
            <div className="bg-teal-50 py-6">
                <div className="relative w-screen max-w-sm border shadow-md shadow-teal-200 mx-auto bg-white px-4 py-8 sm:px-6 lg:px-8 rounded-md" aria-modal="true" role="dialog" tabIndex="-1">
                    <h1 className="text-center text-teal-700 font-bold text-4xl">Order not found</h1>
                </div>
            </div>
        );
    }

    return (
        <div className="bg-teal-50 py-6">
            <div className="relative w-screen max-w-sm border shadow-md shadow-teal-200 mx-auto bg-white px-4 py-8 sm:px-6 lg:px-8 rounded-md" aria-modal="true" role="dialog" tabIndex="-1">
                <h1 className="text-center text-teal-700 font-bold text-4xl">Detail Pesanan</h1>
                <div className="mt-10 space-y-3">
                    <h2 className="text-teal-700 font-semibold">Informasi Pengguna</h2>
                    <p>Nama: {order.user.name}</p>
                    <p>Alamat: {order.alamat}</p>
                    <p className="mb-8">No HP: {order.no_hp}</p>

                    <h2 className="text-teal-700 font-semibold mt-16">Detail Belanja</h2>
                    <ul className="space-y-4">
                        {order.otm.map((item, index) => (
                            <li key={index} className="flex items-center gap-4">
                                <img
                                    src={`${process.env.REACT_APP_API_AGROW}/product/images/${item.product.images}`}
                                    alt=""
                                    className="size-16 rounded object-cover"
                                    width={1920}
                                    height={1080}
                                    quality={100}
                                />
                                <div>
                                    <h3 className="text-md text-teal-700 font-semibold">{item.product.title}</h3>
                                    <dl className="mt-0.5 space-y-px text-[10px] text-gray-400">
                                        <div>
                                            <dt className="inline">Jumlah :</dt>
                                            <dd className="inline"> {item.quantity}</dd>
                                        </div>
                                        <div>
                                            <dt className="inline">Harga:</dt>
                                            <dd className="inline"> {formatRupiah(item.price)}</dd>
                                        </div>
                                    </dl>
                                </div>
                            </li>
                        ))}
                    </ul>

                    <h2 className="text-teal-700 font-semibold mt-6">Total Pembayaran</h2>
                    <p className="text-lg font-bold">{formatRupiah(order.total)}</p>

                    <button
                        className="block rounded bg-teal-700 px-5 py-3 text-sm text-white transition w-full hover:bg-teal-600 mt-6"
                        onClick={handlePayment}
                    >
                        Bayar
                    </button>
                </div>
            </div>
        </div>
    );
}

export default OrderDetail;
