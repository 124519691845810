import React, { useState } from 'react';
import { useNavigate, Outlet, Link } from "react-router-dom";
import Log from "../assets/images/logo.png";

function Login() {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [isError, setIsError] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const router = useNavigate();

    const handleLogin = async () => {
        setIsLoading(true);  // Set loading state to true

        const response = await fetch(process.env.REACT_APP_API_AGROW + "/login", {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*'
            },
            body: JSON.stringify({ email, password }),
        });

        const data = await response.json();
        setIsLoading(false);  // Set loading state to false

        if (data) {
            if (data.status == 200) {
                if (typeof window !== 'undefined') {
                    localStorage.setItem('token', data.token);
                }
                router('/market');
            } else {
                setIsError(data.message);
            }
        }
    };

    return (
        <div className="flex flex-col justify-center h-screen items-center bg-teal-50 pt-20 pb-32">
            {/* <img className='h-24 w-24 md:mr-10 mb-5 mr-8' src={Log} alt="Logo" /> */}
            <div className="flex flex-col max-w-md p-6 rounded-md sm:p-10 shadow-md bg-white shadow-teal-300">
                <div className="mb-8 text-center">
                    <h1 className="my-3 text-4xl font-bold text-teal-600">Login Akun</h1>
                    <p className="text-sm text-gray-400">Silahkan masukkan akun Anda</p>
                </div>
                <form noValidate="" className="space-y-12 flex flex-col items-center">
                    <div className="space-y-4">
                        <div>
                            <label htmlFor="email" className="block mb-2 text-sm">Email</label>
                            <input
                                type="email"
                                name="email"
                                id="email"
                                placeholder="user@gmail.com"
                                className="w-full px-3 py-2 border rounded-md dark:border-gray-300 dark:bg-gray-50 dark:text-gray-800"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                            />
                        </div>
                        <div>
                            <div className="flex justify-between mb-2">
                                <label htmlFor="password" className="text-sm">Password</label>
                                {/* <a rel="noopener noreferrer" href="#" className="text-xs hover:underline dark:text-gray-600">Lupa password?</a> */}
                            </div>
                            <input
                                type="password"
                                name="password"
                                id="password"
                                placeholder="*****"
                                className="w-full px-3 py-2 border rounded-md dark:border-gray-300 dark:bg-gray-50 dark:text-gray-800"
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                            />
                        </div>
                        {isError && (<p className="text-sm text-red-600 italic">{isError}</p>)}
                    </div>
                    <div className="w-52 -mt-24">
                        <button
                            type="button"
                            className="w-full px-8 py-3 font-semibold rounded-md text-white bg-teal-700 hover:bg-teal-800"
                            onClick={handleLogin}
                            disabled={isLoading}  // Disable button when loading
                        >
                            {isLoading ? (
                                <svg className="animate-spin h-5 w-5 text-white mx-auto" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                    <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                                    <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8v8H4z"></path>
                                </svg>
                            ) : (
                                'Masuk'
                            )}
                        </button>
                    </div>
                    <div className="space-y-2">
                        <p className="px-6 text-sm text-center dark:text-gray-600">Belum memiliki akun?
                            <Link rel="noopener noreferrer" to="/" className="hover:underline text-teal-700 font-semibold"> Register</Link>.
                        </p>
                        <p className="px-6 text-sm text-center dark:text-gray-600 mt-3">Kembali ke
                            <Link rel="noopener noreferrer" to="/" className="hover:underline text-teal-700 font-semibold"> Beranda</Link>.
                        </p>
                    </div>
                </form>
            </div>
        </div>
    );
}

export default Login;

