import React, { useState, useEffect } from 'react';
import { Link, useParams, useNavigate } from "react-router-dom";
import CardProductToko from '../components/CardProductToko';
import useMobileDetect from '../function/checkMobile.js';
import SkeletonCard from '../components/SkeletonCard.js';

function Dashboard() {
    const [toko, setToko] = useState(null);
    const [products, setProducts] = useState(null);
    const [searchKeyword, setSearchKeyword] = useState('');
    const isMobile = useMobileDetect();
    const [namaToko, setNamaToko] = useState('');
    const [deskripsi, setDeskripsi] = useState('');
    const [loading, setLoading] = useState(false);

    const { userId } = useParams();
    const router = useNavigate();

    const fetchProducts = async (id, keyword = '') => {
        try {
            let url;
            if (keyword && id) {
                url = `${process.env.REACT_APP_API_AGROW}/product/searchPrToko/${id}/${keyword}`;
            } else {
                url = `${process.env.REACT_APP_API_AGROW}/toko/user/showProduct`;
            }

            const token = window.localStorage.getItem('token');

            const response = await fetch(url, {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json'
                }
            });

            if (!response.ok) {
                throw new Error('Network response was not ok');
            }

            const data = await response.json();
            if (keyword != '' && id != null) {
                setProducts(data.data);
            } else {
                setProducts(data.data[0].product);
            }

        } catch (error) {
            console.error("Error fetching products:", error);
        }
    };

    const fetchToko = async () => {
        try {
            const url = `${process.env.REACT_APP_API_AGROW}/toko/user`;
            const token = window.localStorage.getItem('token');

            const response = await fetch(url, {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json'
                }
            });

            if (!response.ok) {
                throw new Error('Network response was not ok');
            }

            const data = await response.json();

            if (data && data.data && data.data.length > 0) {
                setToko(data.data);
                fetchProducts(); // Fetch all products initially
            }
        } catch (error) {
            console.error("Error fetching toko:", error);
        }
    };

    useEffect(() => {
        fetchToko();
    }, [userId]);

    const handleSearchChange = (e) => {
        const keyword = e.target.value;
        setSearchKeyword(keyword);

        if (keyword.trim() === '') {
            fetchProducts();  // Fetch all products if input is empty
        } else {
            fetchProducts(toko[0].id, keyword);  // Fetch products based on search keyword
        }
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        setLoading(true);
        try {
            const url = `${process.env.REACT_APP_API_AGROW}/toko`;
            const token = window.localStorage.getItem('token');

            const response = await fetch(url, {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    nama_toko: namaToko,
                    deskripsi: deskripsi
                })
            });

            if (!response.ok) {
                throw new Error('Network response was not ok');
            }

            const data = await response.json();
            setToko([data.data]);
            setNamaToko('');
            setDeskripsi('');
            router('/dashboard');
        } catch (error) {
            console.error("Error creating toko:", error);
        } finally {
            setLoading(false);
        }
    };

    console.log(products);

    return (
        <div className="bg-teal-100">
            <div className="container py-6 mx-auto">
                {toko && toko.length > 0 ? (
                    <div className="bg-white shadow-md rounded p-6">
                        {/* Header */}
                        <div className="container mx-auto flex flex-col items-center md:flex-row md:justify-between">
                            <div className="flex items-center mb-4 md:mb-0">
                                <div className="ml-4">
                                    <h1 className="text-2xl font-bold text-teal-600">{toko[0]?.nama_toko}</h1>
                                    <p className="text-gray-300">{toko[0]?.deskripsi}</p>
                                </div>
                            </div>
                        </div>

                        {/* Tools */}
                        <div className="flex justify-between">
                            <Link to="/dashboard/add" className="rounded-md bg-teal-600 px-5 py-2.5 text-sm font-medium text-white transition hover:bg-teal-700 flex items-center ml-4 mt-12">Tambah</Link>

                            <div className="relative mt-12">
                                <input
                                    type="text"
                                    id="rounded-email"
                                    className="rounded-lg border-transparent flex-1 appearance-none border border-slate-400 w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-400 shadow-sm text-base focus:outline-none focus:ring-2 focus:ring-teal-600 focus:border-transparent"
                                    placeholder="Cari Produk..."
                                    value={searchKeyword}
                                    onChange={handleSearchChange}
                                />
                            </div>
                        </div>

                        <div className="col-span-3 overflow-y-auto my-12">
                            <div className="grid grid-cols-2 sm:grid-cols-2 lg:grid-cols-4 gap-6">
                                {products ? (
                                    products.length > 0 ? (
                                        products.map((product) => (
                                            <CardProductToko
                                                key={product.id}
                                                image={`${process.env.REACT_APP_API_AGROW}/product/images/${product.images}`}
                                                title={product.title}
                                                desc={product.description}
                                                price={product.price}
                                                isMarket={true}
                                                isMobile={isMobile}
                                                id={product.id}
                                            />
                                        ))
                                    ) : (
                                        <div className="text-center col-span-4">
                                            <p className="text-xl text-gray-500">Belum ada Produk</p>
                                        </div>
                                    )
                                ) : (
                                    Array.from({ length: 6 }).map((_, index) => (
                                        <SkeletonCard key={index} />
                                    ))
                                )}
                            </div>
                        </div>
                    </div>
                ) : (
                    <div className="mx-auto max-w-screen-xl px-4 py-16 sm:px-6 lg:px-8">
                        <div className="mx-auto max-w-lg pt-8 bg-white">
                            <h1 className="text-center text-2xl font-bold text-teal-600 sm:text-3xl">Ayo daftarkan Toko Anda!</h1>
                            <p className="mx-auto mt-4 max-w-md text-center text-gray-500">
                                Bergabunglah dengan kami dan mulai jualan produk Anda hari ini! Daftarkan toko Anda untuk mendapatkan akses ke platform kami. Jangan lewatkan kesempatan untuk mengembangkan bisnis Anda dengan dukungan penuh dari tim kami.
                            </p>
                            <form onSubmit={handleSubmit} className="mb-0 mt-6 space-y-4 rounded-lg p-4 shadow-lg sm:p-6 lg:p-8 ">
                                <div>
                                    <label htmlFor="nama" className="sr-only">Nama Toko</label>
                                    <div className="relative">
                                        <input
                                            type="text"
                                            id="nama"
                                            className="w-full rounded-lg border-2 border-teal-600 p-4 pe-12 text-sm shadow-sm"
                                            placeholder="Masukkan Nama Toko..."
                                            value={namaToko}
                                            onChange={(e) => setNamaToko(e.target.value)}
                                        />
                                    </div>
                                </div>
                                <div>
                                    <label htmlFor="description" className="sr-only">Deskripsi</label>
                                    <div className="relative">
                                        <textarea
                                            className="w-full rounded-lg border-2 border-teal-600 p-4 pe-12 text-sm shadow-sm"
                                            placeholder="Deskripsi"
                                            rows="8"
                                            id="description"
                                            value={deskripsi}
                                            onChange={(e) => setDeskripsi(e.target.value)}
                                        ></textarea>
                                    </div>
                                </div>
                                <button
                                    type="submit"
                                    className="block w-full rounded-lg bg-teal-600 px-5 py-3 text-sm font-medium text-white"
                                    disabled={loading}
                                >
                                    {loading ? 'Mendaftar...' : 'Daftar'}
                                </button>
                            </form>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
}

export default Dashboard;
