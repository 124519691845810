import React, { useState, useEffect } from 'react';
import CardEducation from './components/CardEducation.js';
import Dropdown from './components/Dropdown.js';
import SkeletonCard from './components/SkeletonCard.js';
import ImageEducation from './assets/images/edukasi.jpeg'


function Education() {
    const [educations, setEducations] = useState([]);
    const [selectedCategory, setSelectedCategory] = useState(null);

    const fetchEducation = async (category = null) => {
        try {
            let url = `${process.env.REACT_APP_API_AGROW}`;
            if (category) {
                url += `/education/by-category/${category.id}`;
            } else {
                url += `/education`;
            }
            const response = await fetch(url);
            const data = await response.json();
            setEducations(data.data);
        } catch (error) {
            console.error("Error fetching educations:", error);
        }
    };

    useEffect(() => {
        fetchEducation(selectedCategory);
    }, [selectedCategory]);

    const handleCategorySelect = (category) => {
        setSelectedCategory(category);
    };
    return (
        <div className='mb-12'>
            <section className="overflow-hidden bg-gray-50 sm:grid sm:grid-cols-2 sm:items-center">
                <div className="p-8 md:p-12 lg:px-16 lg:py-24">
                    <div className="mx-auto max-w-xl text-center ltr:sm:text-left rtl:sm:text-right">
                        <h2 className="text-2xl font-bold text-teal-700 md:text-3xl">
                            Edukasi dan Gaya Hidup Sehat.
                        </h2>

                        <p className="hidden text-gray-500 md:mt-4 md:block">
                            Kami tidak hanya menyediakan produk segar dan berkualitas, tetapi juga berkomitmen untuk meningkatkan pengetahuan Anda tentang pertanian dan kesehatan melalui fitur edukasi kami. Temukan berbagai artikel cara menanam dan merawat tanaman, manfaat kesehatan dari berbagai buah-buahan, serta tips dan trik untuk gaya hidup sehat. Dengan fitur edukasi kami, Anda akan lebih memahami asal-usul makanan Anda dan bagaimana cara terbaik untuk mengonsumsinya demi kesehatan Anda dan keluarga.
                        </p>


                    </div>
                </div>

                <img
                    alt=""
                    src={ImageEducation}
                    className="h-full w-full object-cover sm:h-[calc(100%_-_2rem)] sm:self-end sm:rounded-ss-[30px] md:h-[calc(100%_-_4rem)] md:rounded-ss-[60px]"
                    width={1920}
                    height={1080}
                    quality={100}
                />
            </section>

            <div className='container md:my-12 m-4 md:ml-48'>
                <div className='flex md:flex-row flex-col justify-between'>
                    <h1 className='md:text-3xl text-2xl  text-teal-800 font-bold ml-4 md:ml-0'>Edukasi untuk Anda !</h1>
                    <div className='mr-64 md:ml-0 ml-5 md:mt-0 mt-2'>
                        <Dropdown onCategorySelect={handleCategorySelect} />
                    </div>
                </div>

                <div className='mt-8 md:mr-0 mr-12'>
                    <div className="sm:max-w-xl md:max-w-full lg:max-w-screen-xl">
                        <div className="grid gap-8 lg:grid-cols-3 sm:max-w-sm sm:mx-auto lg:max-w-full ml-5">
                            {educations ? (
                                educations.length > 0 ? (
                                    educations.map((education) => (
                                        <CardEducation key={education.id} thumbnail={`${process.env.REACT_APP_API_AGROW}/education/thumbnail/${education.images}`} category={education.category_edu.nama_education} title={education.name_education} desc={education.description} id={education.id} />
                                    ))
                                ) : (
                                    Array.from({ length: 6 }).map((_, index) => (
                                        <SkeletonCard key={index} />
                                    ))
                                )
                            ) : (
                                Array.from({ length: 6 }).map((_, index) => (
                                    <SkeletonCard key={index} />
                                ))
                            )}
                        </div>
                    </div>
                </div>
            </div>

            <div className='w-full  flex justify-center items-center'>
                <nav aria-label="Pagination" className="inline-flex -space-x-px rounded-md shadow-sm dark:bg-gray-100 dark:text-gray-800">
                    <button type="button" className="inline-flex items-center px-2 py-2 text-sm font-semibold border rounded-l-md dark:border-gray-300 bg-gray-50 hover:bg-gray-100 ">
                        <span className="sr-only">Previous</span>
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true" className="w-5 h-5">
                            <path fillRule="evenodd" d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z" clipRule="evenodd"></path>
                        </svg>
                    </button>
                    <button type="button" aria-current="page" className="text-white inline-flex items-center px-4 py-2 text-sm font-semibold border bg-teal-600 ">1</button>
                    <button type="button" className="text-black inline-flex items-center px-4 py-2 text-sm font-semibold border dark:border-gray-300">2</button>

                    <button type="button" className="inline-flex items-center px-2 py-2 text-sm font-semibold border rounded-r-md dark:border-gray-300 bg-gray-50 hover:bg-gray-100">
                        <span className="sr-only">Next</span>
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true" className="w-5 h-5">
                            <path fillRule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clipRule="evenodd"></path>
                        </svg>
                    </button>
                </nav>
            </div>
        </div>
    );
}

export default Education;
