import React from 'react';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Navbar from "./components/Navbar.js";
import Footer from './components/Footer.js';
import Home from './Home.js';
import About from './About.js';
import Contact from './Contact.js';
import Market from './Market.js';
import ProductDetail from './market/ProductDetail.js';
import Education from './Education.js';
import EduDetail from './education/EducationDetail.js';
import Login from './auth/Login.js';
import Dashboard from './dashboard/Dashboard.js'
import AddProduct from './dashboard/add/AddProduct.js';
import UpdateProduct from './dashboard/update/UpdateProduct.js';
import CartUser from './cart/Cart.js';
import Checkout from './checkout/Checkout.js';
import OrderDetail from './order/Order.js';


function App() {
  return (
    <BrowserRouter>
      <Navbar />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about" element={<About />} />

        {/* AUTH */}
        <Route path="/auth/login" element={<Login />} />

        {/* Dashboard */}
        <Route path="/dashboard" element={<Dashboard />} />
        <Route path="/dashboard/add" element={<AddProduct />} />
        <Route path="/dashboard/update/:id" element={<UpdateProduct />} />

        {/* Cart */}
        <Route path="/cart" element={<CartUser />} />

        {/* Checkout */}
        <Route path="/checkout" element={<Checkout />} />

        {/* Order */}
        <Route path="/orders/:orderId" element={<OrderDetail />} />

        {/* Product */}
        <Route path="/market" element={<Market />} />
        <Route path="/market/:id" element={<ProductDetail />} />

        {/* Educatio */}
        <Route path="/education" element={<Education />} />
        <Route path="/education/:id" element={<EduDetail />} />


        {/* Contact */}
        <Route path="/contact" element={<Contact />} />
      </Routes>
      <Footer />
    </BrowserRouter>
  );
}

export default App;
