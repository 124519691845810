import React from 'react'
import { Link } from "react-router-dom";
import truncateText from "../utils/ShortText.js"
import formatRupiah from "../utils/Rupiah.js"




function CardProductToko({ image, title, price, desc, isMarket, isMobile, id, isToko }) {



    return (
        <Link to={"/dashboard/update/" + id}>
            <div className="md:max-w-xs w-full overflow-hidden bg-white rounded-lg shadow-xl shadow-teal-100">
                <div className="px-4 py-2">
                    <h1 className="text-xl font-bold text-teal-900 uppercase ">{title}</h1>
                    <p className="mt-1 text-sm text-gray-400 dark:text-gray-400">
                        {truncateText(desc, 10)} {/* Ganti angka 10 dengan jumlah kata yang diinginkan */}
                    </p>
                </div>

                <img className="object-cover w-full h-48 mt-2" src={image} alt={title} width={1920}
                    height={1080}
                    quality={100} />

                <div className="flex items-center justify-between px-4 py-2 bg-teal-500">
                    <h1 className={isMarket == true ? "md:text-lg text-xs font-bold text-white" : "text-lg font-bold text-white"}>{formatRupiah(price)}</h1>

                </div>
            </div>
        </Link>
    )
}

export default CardProductToko
