import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from "react-router-dom";
import formatRupiah from '../utils/Rupiah.js';
import { BadgeCheckIcon } from '@heroicons/react/solid';

const ProductDetail = () => {
    const [quantity, setQuantity] = useState(1);
    const [product, setProduct] = useState(null);
    const [loading, setLoading] = useState(true);
    const [toko, setToko] = useState(null);
    const [myToko, setMyToko] = useState([]);
    const [error, setError] = useState(null);
    const navigate = useNavigate();  // Renamed from 'router' to 'navigate' for better clarity
    const { id } = useParams();
    const idProduct = id;

    const handleQuantityChange = (e) => {
        setQuantity(e.target.value);
    };

    const addToCart = async () => {
        let token;
        if (typeof window !== 'undefined') {
            token = window.localStorage.getItem('token');
        }

        // Redirect to login if no token is found
        if (!token) {
            return navigate('/auth/login');
        }

        // Prepare the request payload
        const requestPayload = {
            id_product: idProduct,
            quantity: quantity,
            id_toko: myToko.length > 0 ? myToko[0].id : 0,
        };

        try {
            const response = await fetch(`${process.env.REACT_APP_API_AGROW}/cart/add`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                },
                body: JSON.stringify(requestPayload),
            });

            const data = await response.json();

            if (response.ok) {
                if (data.status === 201) {
                    alert(`${quantity} ditambahkan ke keranjang`);
                    navigate(`/cart`);
                } else {
                    setError(data.message);
                }
            } else {
                setError(data.message || 'Terjadi kesalahan saat menambahkan ke keranjang.');
            }
        } catch (error) {
            console.error('Error during add to cart:', error);
            setError('Terjadi kesalahan saat menambahkan ke keranjang.');
        }
    };

    const fetchProduct = async () => {
        try {
            const response = await fetch(`${process.env.REACT_APP_API_AGROW}/product/detail/${idProduct}`);
            const data = await response.json();
            setProduct(data.data);
            setToko(data.data.toko);
            setLoading(false);
        } catch (error) {
            console.error("Error fetching product:", error);
            setLoading(false);
            setError('Gagal mengambil data produk.');
        }
    };

    const fetchToko = async () => {
        try {
            const url = `${process.env.REACT_APP_API_AGROW}/toko/user`;
            const token = window.localStorage.getItem('token');

            const response = await fetch(url, {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json',
                },
            });

            if (!response.ok) {
                throw new Error('Gagal mengambil data toko.');
            }

            const data = await response.json();
            setMyToko(data.data);
        } catch (error) {
            console.error("Error fetching toko:", error);
            setError('Gagal mengambil data toko.');
        }
    };

    useEffect(() => {
        fetchProduct();
        fetchToko();
    }, []);

    return (
        <div className="bg-teal-50 flex flex-col h-screen pt-20">
            <div className="max-w-4xl mx-auto p-4">
                <div className="bg-white shadow-lg rounded-lg p-6 flex flex-col lg:flex-row">
                    {loading ? (
                        <div className="flex w-full">
                            <div className="animate-pulse w-1/2 pr-4">
                                <div className="bg-gray-300 h-64 w-full mb-4"></div>
                            </div>
                            <div className="animate-pulse w-1/2 pl-4">
                                <div className="bg-gray-300 h-8 w-3/4 mb-2"></div>
                                <div className="bg-gray-300 h-6 w-1/2 mb-2"></div>
                                <div className="bg-gray-300 h-20 w-full mb-4"></div>
                                <div className="bg-gray-300 h-10 w-1/4"></div>
                            </div>
                        </div>
                    ) : (
                        <div className="flex flex-col lg:flex-row">
                            <div className="lg:w-1/2 mb-4 lg:mb-0">
                                <img src={`${process.env.REACT_APP_API_AGROW}/product/images/${product.images}`} alt="Product" className="w-full h-auto" />
                            </div>
                            <div className="lg:w-1/2 lg:pl-6 flex flex-col">
                                <div className="flex items-center mb-3">
                                    <BadgeCheckIcon className="w-7 h-7 text-teal-700 mr-1" />
                                    {toko && (
                                        <h5 className="text-slate-900 text-md font-bold">{toko.nama_toko}</h5>
                                    )}
                                </div>
                                <h2 className="text-2xl font-bold mb-2 text-teal-600">{product.title}</h2>
                                <div className="mb-10">
                                    <span className="inline-block bg-teal-100 text-teal-800 text-sm font-semibold mr-2 px-2.5 py-0.5 rounded-xl">{product.category.nama}</span>
                                </div>
                                <h2 className="text-3xl font-bold mb-2 text-teal-600">{formatRupiah(product.price)}</h2>
                                <p className="text-gray-700 mb-4">{product.description}</p>
                                <div className="flex items-center mb-4">
                                    <label className="mr-2 font-semibold" htmlFor="quantity">Jumlah:</label>
                                    <input
                                        type="number"
                                        id="quantity"
                                        name="quantity"
                                        value={quantity}
                                        onChange={handleQuantityChange}
                                        className="border rounded w-16 text-center"
                                        min="1"
                                    />
                                </div>
                                <button
                                    onClick={addToCart}
                                    className="bg-teal-700 text-white px-4 py-2 rounded hover:bg-teal-800 mt-2 lg:mt-0 font-bold"
                                >
                                    Keranjang
                                </button>
                                {error && <p className="text-sm text-left mt-8 italic text-red-600 sm:text-sm">{error}</p>}
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

export default ProductDetail;
