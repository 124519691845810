import React from 'react';
import { Link } from 'react-router-dom';
import truncateText from '../utils/ShortText.js';

function CardEducation({ thumbnail, category, title, desc, id }) {
    return (
        <div className="overflow-hidden rounded-lg shadow-lg h-90 w-full md:w-full">
            <div className="block w-full h-full">
                <img src={thumbnail} className="object-cover w-full max-h-40" alt={title} width={1920} height={1080} quality={100} />
                <div className="w-full p-4 bg-white  group-hover:bg-teal-500">
                    <p className="font-medium text-teal-500 text-md group-hover:text-white">
                        {category}
                    </p>
                    <p className="mb-2 text-xl font-medium text-gray-800 group-hover:text-white">
                        {title}
                    </p>
                    <p className="font-light text-gray-400  text-md group-hover:text-white">
                        {truncateText(desc, 6)}
                    </p>
                    <Link to={`/education/${id}`} className="group cursor-pointer">
                        <div className="flex items-center justify-center mt-4 border-2 border-teal-500 p-1 rounded-lg group-hover:bg-teal-500">
                            <h3 className="text-center font-semibold group-hover:text-white">Detail</h3>
                        </div>
                    </Link>
                </div>
            </div>
        </div>
    );
}

export default CardEducation;
