import React from 'react';
import { Link } from 'react-router-dom';
import { faShoppingCart } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import truncateText from "../utils/ShortText.js";
import formatRupiah from "../utils/Rupiah.js";

function CardProduct({ img, title, price, desc, isMarket, isMobile, id, isToko }) {
    return (
        <Link to={`/market/${id}`}>
            <div className="md:max-w-xs w-full overflow-hidden bg-white rounded-lg shadow-xl shadow-teal-100 ">
                <div className="px-4 py-2">
                    <h1 className="text-xl font-bold text-teal-900 uppercase">{title}</h1>
                    <p className="mt-1 text-sm text-gray-400 dark:text-gray-400">
                        {truncateText(desc, 10)} {/* Ganti angka 10 dengan jumlah kata yang diinginkan */}
                    </p>
                </div>

                <img
                    className="object-cover w-full h-48 mt-2"
                    src={img}
                    alt={title}
                    width={1920}
                    height={1080}
                    quality={100}
                />

                <div className="flex items-center justify-between px-4 py-2 bg-teal-500">
                    <h1 className={isMarket ? "md:text-lg text-xs font-bold text-white" : "text-lg font-bold text-white"}>
                        {formatRupiah(price)}
                    </h1>
                    <button
                        className={
                            isMarket && isMobile
                                ? "p-1 text-xs font-semibold text-gray-900 uppercase transition-colors duration-300 transform bg-white rounded hover:bg-gray-200 focus:bg-gray-400 focus:outline-none"
                                : "px-2 py-1 text-xs font-semibold text-gray-900 uppercase transition-colors duration-300 transform bg-white rounded hover:bg-gray-200 focus:bg-gray-400 focus:outline-none"
                        }
                    >
                        {isMarket && isMobile ? (
                            <FontAwesomeIcon icon={faShoppingCart} className="w-3 h-3 text-teal-400" />
                        ) : (
                            "Add to cart"
                        )}
                    </button>
                </div>
            </div>
        </Link>
    );
}

export default CardProduct;
