import React from 'react'
import Petani from './assets/images/petani.png'

function Contact() {
    return (
        <div className='container mx-auto'>
            <section className="dark:bg-gray-100 text-teal-700 mx-auto">
                <div className="container flex flex-col justify-center px-4 py-8 mx-auto md:p-8">
                    <h2 className="text-2xl font-semibold sm:text-4xl">FAQ.</h2>
                    <p className="mt-4 mb-8 text-gray-300">Beragam pertanyaan umum terkait platform layanan kami.</p>
                    <div className="space-y-4">
                        <details className="w-full border rounded-lg">
                            <summary className="px-4 py-6 ">Ex orci laoreet egestas sapien magna egestas scelerisque?</summary>
                            <p className="px-4 py-6 pt-0 ml-4 -mt-4 text-gray-300">Lectus iaculis orci metus vitae ligula dictum per. Nisl per nullam taciti at adipiscing est. </p>
                        </details>
                    </div>
                </div>
            </section>

            <div className="grid max-w-screen-xl grid-cols-1 gap-8 px-8 py-16  rounded-lg md:grid-cols-2 md:px-12 lg:px-16 xl:px-32 dark:bg-gray-100 dark:text-gray-800 mx-auto">
                <div className="flex flex-col justify-between">
                    <div className="space-y-2">
                        <h2 className="text-4xl font-bold leading-tight lg:text-5xl text-teal-700">Let's talk!</h2>
                        <div className="text-gray-300">Yuk komunikasikan bisnismu bersama kami.</div>
                    </div>
                    <img src={Petani} alt="petani" className="p-2 h-52 md:h-full" />
                </div>
                <form noValidate="" className="space-y-6">
                    <div>
                        <label htmlFor="name" className="text-sm">Full name</label>
                        <input id="name" type="text" placeholder="" className="w-full p-3 rounded border-2 border-teal-600 active:border-teal-800" />
                    </div>
                    <div>
                        <label htmlFor="email" className="text-sm">Email</label>
                        <input id="email" type="email" className="w-full p-3 rounded border-2 border-teal-600 active:border-teal-800" />
                    </div>
                    <div>
                        <label htmlFor="message" className="text-sm">Message</label>
                        <textarea id="message" rows="3" className="w-full p-3 rounded border-2 border-teal-600 active:border-teal-800"></textarea>
                    </div>
                    <button type="submit" className="w-full p-3 text-sm font-bold tracking-wide uppercase rounded bg-teal-600 text-white">Kirim Pesan</button>
                </form>
            </div>
        </div>
    )
}

export default Contact
