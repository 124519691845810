import React, { useState, useEffect } from 'react';
import CardProduct from './components/CardProduct.js';
import useMobileDetect from './function/checkMobile.js';
import CarouselMarket from './components/CarouselMarket.js';
import SkeletonCard from './components/SkeletonCard.js';
import { faBoxOpen, faSeedling, faSearch } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

function Market() {
    let isMobile = useMobileDetect();

    const [products, setProducts] = useState([]);
    const [categories, setCategories] = useState([]);
    const [selectedCategory, setSelectedCategory] = useState(null);
    const [searchKeyword, setSearchKeyword] = useState('');

    const fetchProducts = async (categoryId = null, keyword = '') => {
        try {
            const baseUrl = process.env.REACT_APP_API_AGROW;
            let url = `${baseUrl}/product`;

            if (categoryId) {
                url = `${baseUrl}/product/by-category/${categoryId}`;
            } else if (keyword) {
                url = `${baseUrl}/product/search/${keyword}`;
            }

            const response = await fetch(url);
            const data = await response.json();
            setProducts(data.data);
        } catch (error) {
            console.error("Error fetching products:", error);
        }
    };

    const fetchCategories = async () => {
        try {
            const response = await fetch(`${process.env.REACT_APP_API_AGROW}/category-product`);
            const data = await response.json();
            setCategories(data.data);
        } catch (error) {
            console.error("Error fetching categories:", error);
        }
    };

    useEffect(() => {
        fetchProducts(selectedCategory, searchKeyword);
        fetchCategories();
    }, [selectedCategory, searchKeyword]);

    const handleCategoryClick = (categoryId) => {
        setSelectedCategory(categoryId);
    };

    const handleSearchChange = (e) => {
        setSearchKeyword(e.target.value);
    };

    return (
        <section className="text-gray-800 mb-8">
            <div className="max-w-7xl p-6 mx-auto space-y-6 sm:space-y-12">
                <CarouselMarket />

                <div className='md:flex justify-between md:flex-row flex-col w-full my-2 hidden'>
                    <h3 className='text-teal-700 md:text-4xl font-bold text-2xl mb-1'>Produk terbaik untukmu !</h3>
                    <div className="flex relative">
                        <span className="rounded-l-md inline-flex items-center px-3 border-t bg-teal-700 border-l border-b border-gray-300 text-gray-500 shadow-sm text-sm">
                            <FontAwesomeIcon icon={faSearch} className="w-5 h-5 text-white" />
                        </span>
                        <input
                            type="text"
                            id="search-input"
                            className="rounded-r-lg flex-1 appearance-none border border-gray-300 w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-400 shadow-sm text-base focus:outline-none focus:ring-2 focus:ring-teal-600 focus:border-transparent"
                            name="search"
                            placeholder="Cari Produk..."
                            value={searchKeyword}
                            onChange={handleSearchChange}
                        />
                    </div>
                </div>

                <div className="grid grid-cols-1 lg:grid-cols-4 gap-6">
                    <div className="col-span-3 md:col-span-1">
                        <aside className="sticky flex flex-col md:w-64 md:h-[500px] h-[300px] px-5 py-8 overflow-y-auto bg-white shadow-md border-1 border-gray-900 rounded-lg shadow-teal-400 w-full">
                            <h1 className='text-teal-600 text-lg uppercase font-bold'>Kategori Produk</h1>
                            <h1 className='text-gray-300 text-sm'>Bebas pilih sesukamu !</h1>
                            <div className="flex flex-col justify-between flex-1 mt-6">
                                <nav className="flex-1 -mx-3 space-y-3">
                                    <button
                                        className="w-full flex items-center px-3 py-2 text-gray-600 transition-colors duration-300 transform rounded-lg hover:bg-teal-100 hover:text-gray-700"
                                        onClick={() => handleCategoryClick(null)}
                                    >
                                        <FontAwesomeIcon icon={faBoxOpen} className="w-5 h-5 text-teal-700" />
                                        <span className="mx-2 text-sm font-medium">Semua Kategori</span>
                                    </button>

                                    {categories.length > 0 ? (
                                        categories.map((category) => (
                                            <button
                                                key={category.id}
                                                className="w-full flex items-center px-3 py-2 text-gray-600 transition-colors duration-300 transform rounded-lg hover:bg-teal-100 hover:text-gray-700"
                                                onClick={() => handleCategoryClick(category.id)}
                                            >
                                                <FontAwesomeIcon icon={faSeedling} className="w-5 h-5 text-teal-700" />
                                                <span className="mx-2 text-sm font-medium">{category.nama}</span>
                                            </button>
                                        ))
                                    ) : (
                                        <p>No categories found.</p>
                                    )}
                                </nav>
                            </div>
                        </aside>
                    </div>

                    <div className='flex justify-between md:flex-row flex-col w-full my-2 md:hidden'>
                        <h3 className='text-teal-700 md:text-4xl font-bold text-2xl mb-1'>Produk terbaik untukmu !</h3>
                        <div className="flex relative">
                            <span className="rounded-l-md inline-flex items-center px-3 border-t bg-teal-700 border-l border-b border-gray-300 text-gray-500 shadow-sm text-sm">
                                <FontAwesomeIcon icon={faSearch} className="w-5 h-5 text-white" />
                            </span>
                            <input
                                type="text"
                                id="search-input"
                                className="rounded-r-lg flex-1 appearance-none border border-gray-300 w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-400 shadow-sm text-base focus:outline-none focus:ring-2 focus:ring-teal-600 focus:border-transparent"
                                name="search"
                                placeholder="Cari Produk..."
                                value={searchKeyword}
                                onChange={handleSearchChange}
                            />
                        </div>
                    </div>

                    <div className="col-span-3 max-h-[500px] overflow-y-auto">
                        <div className="grid grid-cols-2 sm:grid-cols-2 lg:grid-cols-3 gap-6">
                            {products.length > 0 ? (
                                products.map((product) => (
                                    <CardProduct
                                        key={product.id}
                                        img={`${process.env.REACT_APP_API_AGROW}/product/images/${product.images}`}
                                        title={product.title}
                                        desc={product.description}
                                        price={product.price}
                                        isMarket={true}
                                        isMobile={isMobile}
                                        id={product.id}
                                    />
                                ))
                            ) : (
                                Array.from({ length: 6 }).map((_, index) => (
                                    <SkeletonCard key={index} />
                                ))
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default Market;
