import React, { useState, useEffect } from 'react'
import SkeletonLoader from '../components/SkeletonLoader.js';
import { useParams } from "react-router-dom";


function EduDetail() {
    const [education, setEducation] = useState(null);
    const [loading, setLoading] = useState(true);
    const { id } = useParams();

    const fetchEducation = async () => {
        try {
            const response = await fetch(process.env.REACT_APP_API_AGROW + "/education/detail/" + id);
            const data = await response.json();
            setEducation(data.data);
            setLoading(false); // Mengubah status loading setelah data diterima
        } catch (error) {
            console.error("Error fetching education details:", error);
            setLoading(false); // Mengubah status loading meskipun terjadi kesalahan
        }
    };

    useEffect(() => {
        fetchEducation();
    }, []);

    console.log(education);

    return (
        <div>
            {loading ? (
                <SkeletonLoader />
            ) : (
                <div className='bg-teal-100'>
                    <div className="p-5 mx-auto sm:p-10 md:p-16 shadow-lg">
                        <div className="flex flex-col max-w-3xl md:max-w-5xl mx-auto overflow-hidden rounded shadow-lg md:my-22 bg-white z-99">
                            <img src={`${process.env.REACT_APP_API_AGROW}/education/thumbnail/${education.images}`} alt={id} className="w-full h-96 sm:h-96 bg-cover" width={1920}
                                height={1080}
                                quality={100} />
                            <div className="p-6 pb-12 m-4 mx-auto -mt-16 space-y-6 lg:max-w-5xl sm:px-10 sm:mx-12 h-58 lg:rounded-md bg-teal-500 overflow-y-auto">
                                <div className="space-y-2">
                                    <a rel="noopener noreferrer" href="#" className="inline-block text-2xl font-semibold sm:text-3xl text-white">{education.name_education}</a>
                                    <p className="text-xl text-white">
                                        <a rel="noopener noreferrer" href="#" className="text-xl hover:underline text-white">{education.category_edu.nama_education}</a>
                                    </p>
                                </div>
                                <div className="text-white">
                                    <p className="text-justify">{education.description}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </div>
    )
}

export default EduDetail;
