import React, { useState, useEffect } from 'react';

const Dropdown = ({ onCategorySelect }) => {
    const [isOpen, setIsOpen] = useState(false);
    const [categories, setCategories] = useState([]);

    const toggleDropdown = () => {
        setIsOpen(!isOpen);
    };

    const fetchCategory = async () => {
        try {
            const url = `${process.env.REACT_APP_API_AGROW}/category-edu`;
            const response = await fetch(url);
            const data = await response.json();
            setCategories(data.data);
        } catch (error) {
            console.error("Error fetching categories:", error);
        }
    };

    useEffect(() => {
        fetchCategory();
    }, []);

    const handleCategorySelect = (category) => {
        onCategorySelect(category);
        setIsOpen(false);
    };

    return (
        <div className="relative inline-block text-left">
            <button
                onClick={toggleDropdown}
                className="bg-teal-600 text-white font-semibold py-2 px-4 rounded inline-flex items-center"
            >
                <span>Category</span>
                <svg className="fill-current w-4 h-4 ml-2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" /></svg>
            </button>

            {isOpen && (
                <div className="absolute right-0 mt-2 w-48 bg-white border border-gray-200 rounded shadow-lg">
                    {categories.length > 0 ? (
                        categories.map((category) => (
                            <button
                                key={category.id}
                                onClick={() => handleCategorySelect(category)}
                                className="block px-4 py-2 text-gray-800 hover:bg-gray-100 w-full text-left"
                            >
                                {category.nama_education}
                            </button>
                        ))
                    ) : (
                        <p>No categories found</p>
                    )}
                </div>
            )}
        </div>
    );
};

export default Dropdown;
